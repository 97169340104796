<template>
  <div class="error-screen">
    <h1 class="q-mb-sm">404</h1>
    <h3 class="q-mb-sm">Page not found</h3>
    <p>The page you are looking for does not exist.</p>

    <q-btn color="secondary" label="Take Me Home" class="q-mt-lg" to="/" />
  </div>
</template>

<script>
export default {
  name: "ErrorScreen"
};
</script>

<style scoped lang="scss">
.error-screen {
  width: 900px;
  max-width: 100%;
  padding: $space-md;
  margin: 0 auto;
  @include full-screen-height;
  @include display-flex(column, center, center);
  text-align: center;
}
</style>
